@font-face {
  font-family: Satoshi-light;
  src: url(https://ik.imagekit.io/Suresh/Fonts/Satoshi/Satoshi-Light);
  font-weight: 300;
}
@font-face {
  font-family: Satoshi-Regular;
  src: url(https://ik.imagekit.io/Suresh/Fonts/Satoshi/Satoshi-Regular);
  font-weight: 400;
}
@font-face {
  font-family: Satoshi-Medium;
  src: url(https://ik.imagekit.io/Suresh/Fonts/Satoshi/Satoshi-Medium);
  font-weight: 500;
}
@font-face {
  font-family: Satoshi-bold;
  src: url(https://ik.imagekit.io/Suresh/Fonts/Satoshi/Satoshi-Bold);
  font-weight: 700;
}
@font-face {
  font-family: Satoshi-black;
  src: url(https://ik.imagekit.io/Suresh/Fonts/Satoshi/Satoshi-Black);
  font-weight: 900;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Satoshi-Regular;
}

body {
  background-color: #f2f2f2;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

.container {
  max-width: 1320px;
  margin: 0 auto;
  width: 96%;
}

.content{
  padding-bottom: 30px;
}

header {
  nav {
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0;

      .logo {
        width: 150px;
      }

      .mobilemenu {
        display: none;
      }

      .visible_false {
        display: none;
      }

      .visible_true {
        display: flex;
      }

      ul {
        display: flex;
        align-items: center;
        gap: 50px;
        position: relative;

        .close {
          display: none;
          position: absolute;
          right: 15px;
          top: 15px;
        }

        li {
          font-size: 18px;
          font-family: Satoshi-Medium;
        }
        a {
          font-size: 18px;
          font-family: Satoshi-Medium;
          color: #000;
          transition: all 0.3s;
        }
        a:hover {
          color: blueviolet;
        }
      }
    }
  }
}

.hero {
  height: calc(100vh - 102px);
  display: grid;
  place-content: center;

  form {
    display: flex;
    align-items: center;
    gap: 20px;
    background-color: #fff;
    padding: 0 0 0 30px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    overflow: hidden;

    img {
      width: 30px;
      user-select: none;
    }

    input {
      border: 0;
      width: 400px;
      height: 70px;
      outline: 0;
      font-size: 18px;
      font-family: Satoshi-medium;
    }

    button {
      height: 70%;
      padding: 0 5px 0 10px;
      display: block;
      border: 0;
      background: transparent;
      display: none;

      img {
        display: block;
        width: 25px;
      }
    }

    input::placeholder {
      user-select: none;
    }
  }
}

.fullbox {
  height: 100vh;
  display: grid;
  place-content: center;
  user-select: none;

  .err {
    display: flex;
    gap: 30px;

    span {
      font-size: 22px;
      font-family: Satoshi-bold;
    }

    hr {
      height: 100%;
      width: 3px;
      background: #000;
    }
  }

  button {
    width: 150px;
    height: 45px;
    border: 0;
    background-color: lightgrey;
    font-family: satoshi-medium;
    cursor: pointer;
    border-radius: 5px;
    display: block;
    margin: 0 auto;
    margin-top: 30px;
    transition: all 0.2s;
  }
  button:hover {
    background-color: #000;
    color: #fff;
  }
}

.main {
  .flx {
    display: flex;
    gap: 100px;
    align-items: center;
    padding: 50px 0;
    margin-top: 30px;

    .userimg {
      width: 200px;
      height: 200px;
      min-width: 200px;
      outline: 4px solid #0366d6;
      border-radius: 50%;
      overflow: hidden;
      outline-offset: 3px;

      img {
        width: 100%;
      }
    }

    .userdata {
      h2 {
        font-size: 28px;
        font-family: Satoshi-Medium;

        span {
          font-size: 14px;
          margin-left: 5px;
        }
      }
      p {
        font-size: 18px;
        font-family: Satoshi-Medium;
        margin-top: 10px;

        span {
          font-family: Satoshi-Medium;
          font-size: 15px;
        }
      }

      .fol {
        margin-top: 10px;
        display: flex;
        align-items: center;
        gap: 50px;

        p {
          font-size: 16px;
          margin: 0;
          cursor: pointer;
          color: #0366d6;
          user-select: none;
        }
      }

      .joined,
      .about {
        color: grey;
      }

      .otherinfo {
        display: flex;
        align-items: center;
        gap: 30px;
        margin-top: 10px;

        a,
        span {
          display: flex;
          gap: 6px;
          align-items: center;
          color: grey;
          max-width: 250px;
          overflow: hidden;
          position: relative;

          img {
            width: 20px;
          }
        }
      }
    }
  }

  .socialbox {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(10px);
    z-index: 1000000;
  }

  .closeoverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .bx {
    width: 60%;
    max-width: 600px;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 20px 40px;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    border-radius: 20px 20px 0 0;
    max-height: 600px;
    overflow-y: scroll;
    z-index: 1000;
    animation: slide 0.2s linear;

    @keyframes slide {
      0% {
        transform: translatey(100%) translateX(-50%);
      }
      100% {
        transform: translatey(0) translateX(-50%);
      }
    }

    .indicator {
      text-align: center;
      font-size: 22px;
      font-family: Satoshi-Medium;
    }

    .person {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 25px 0;

      .details {
        display: flex;
        align-items: center;
        gap: 20px;

        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }

        p {
          font-size: 18px;
          font-family: Satoshi-Medium;
        }
      }

      button {
        background-color: #d0d0d0;
        border: 0;
        padding: 8px 20px;
        border-radius: 5px;
        font-family: Satoshi-Medium;
        cursor: pointer;
      }

      button:hover {
        background-color: #000;
        color: #fff;
      }
    }
  }

  .bx::-webkit-scrollbar {
    width: 3px;
    padding-top: 10px;
    overflow: hidden;
  }
  .bx::-webkit-scrollbar-button {
    height: 10px;
    opacity: 0;
  }
  .bx::-webkit-scrollbar-thumb {
    background-color: #bfbfbf;
    border-radius: 10px;
  }

  .repos {
    margin-top: 50px;
    position: relative;

    h2 {
      text-align: center;
    }

    .norepos {
      position: absolute;
      width: 100%;
      text-align: center;
      p {
        text-align: center;
        font-size: 18px;
        font-family: Satoshi-Medium;
      }
    }

    .reposboxes {
      margin-top: 100px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 30px;

      .repo_single {
        background-color: #fff;
        padding: 20px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        border-radius: 10px;
        position: relative;

        img {
          width: 60px;
        }

        h3 {
          margin-top: 30px;
          font-size: 18px;
          font-family: Satoshi-Medium;
        }

        p {
          font-size: 14px;
          font-family: Satoshi-Medium;
          margin-top: 10px;
          height: 60px;
          position: relative;
          z-index: 10;
          overflow: hidden;
        }

        span {
          font-size: 14px;
          font-family: Satoshi-Medium;
        }

        p::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            #ffffff 64.06%
          );
        }

        .infos {
          display: flex;
          margin-top: 30px;
          align-items: center;
          gap: 30px;
          img {
            width: 20px;
          }

          a,
          span {
            display: block;
            font-family: Satoshi-Medium;
            display: flex;
            align-items: center;
            gap: 10px;
          }
        }
      }
    }
  }
}

.loader {
  position: fixed;
  height: calc(100vh - 103px);
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 1000000;
  display: grid;
  place-content: center;

  .spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #d0d0d0;
    border-radius: 50%;
    border-left: 4px solid #000;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

@media (max-width: 767px) {
  .main .flx {
    flex-direction: column;
    gap: 50px;

    .userdata {
      width: 100%;
      text-align: center;

      .fol,
      .otherinfo {
        justify-content: center;
      }
    }
  }

  .main .repos .reposboxes {
    grid-template-columns: repeat(2, 1fr);
  }

  .main .bx {
    width: 80%;
  }
}

@media (max-width: 650px) {
  .main .repos .reposboxes {
    gap: 15px;
  }
}

@media (max-width: 550px) {
  .main .flx .userdata .otherinfo {
    flex-direction: column;
    margin-top: 20px;
  }

  .main .bx .person .details p {
    font-size: 16px;
  }

  .main .bx {
    width: 90%;
    padding: 20px;
  }

  .main .bx .person .details {
    gap: 10px;
  }

  .main .bx .person .details img{
    width: 40px;
    height: 40px;
  }

  .main .bx .person button {
    padding: 8px 10px;
  }

  .main .repos .reposboxes {
    grid-template-columns: repeat(1, 1fr);
  }

  header nav .container .logo {
    width: 130px;
  }
}

@media (max-width: 767px) {
  .main .flx .userdata h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .main .flx .userdata p,
  .main .flx .userdata p span {
    font-size: 14px;
  }

  .main .flx .userdata .otherinfo {
    gap: 15px;
  }

  .hero {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hero form {
    padding: 0 10px;
    width: 90%;
    gap: 0;

    input {
      height: 55px;
    }

    button {
      display: block;
    }
  }

  .hero form img {
    display: none;
  }

  input::placeholder {
    font-size: 14px;
  }

  .hero form input {
    width: 100%;
    font-size: 16px;
  }

  .pcmenu {
    display: none;
  }

  header nav .container .mobilemenu {
    display: block;
    position: relative;
  }

  header nav .container ul {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 50px;
    position: absolute;
    height: max-content;
    background: #fff;
    width: max-content;
    right: 0;
    top: -10pz;
    z-index: 10000000;
    padding: 0 100px;
    padding-top: 70px;
    padding-bottom: 30px;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 0 0 10px 10px;

    .close {
      display: block;
    }
  }
}
