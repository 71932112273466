@font-face {
  font-family: Satoshi-light;
  src: url(https://ik.imagekit.io/Suresh/Fonts/Satoshi/Satoshi-Light);
  font-weight: 300;
}
@font-face {
  font-family: Satoshi-Regular;
  src: url(https://ik.imagekit.io/Suresh/Fonts/Satoshi/Satoshi-Regular);
  font-weight: 400;
}
@font-face {
  font-family: Satoshi-Medium;
  src: url(https://ik.imagekit.io/Suresh/Fonts/Satoshi/Satoshi-Medium);
  font-weight: 500;
}
@font-face {
  font-family: Satoshi-bold;
  src: url(https://ik.imagekit.io/Suresh/Fonts/Satoshi/Satoshi-Bold);
  font-weight: 700;
}
@font-face {
  font-family: Satoshi-black;
  src: url(https://ik.imagekit.io/Suresh/Fonts/Satoshi/Satoshi-Black);
  font-weight: 900;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Satoshi-Regular;
}

body {
  background-color: #f2f2f2;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

.container {
  max-width: 1320px;
  margin: 0 auto;
  width: 96%;
}

.content {
  padding-bottom: 30px;
}

header nav .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
}
header nav .container .logo {
  width: 150px;
}
header nav .container .mobilemenu {
  display: none;
}
header nav .container .visible_false {
  display: none;
}
header nav .container .visible_true {
  display: flex;
}
header nav .container ul {
  display: flex;
  align-items: center;
  gap: 50px;
  position: relative;
}
header nav .container ul .close {
  display: none;
  position: absolute;
  right: 15px;
  top: 15px;
}
header nav .container ul li {
  font-size: 18px;
  font-family: Satoshi-Medium;
}
header nav .container ul a {
  font-size: 18px;
  font-family: Satoshi-Medium;
  color: #000;
  transition: all 0.3s;
}
header nav .container ul a:hover {
  color: blueviolet;
}

.hero {
  height: calc(100vh - 102px);
  display: grid;
  place-content: center;
}
.hero form {
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: #fff;
  padding: 0 0 0 30px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 10px;
  overflow: hidden;
}
.hero form img {
  width: 30px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.hero form input {
  border: 0;
  width: 400px;
  height: 70px;
  outline: 0;
  font-size: 18px;
  font-family: Satoshi-medium;
}
.hero form button {
  height: 70%;
  padding: 0 5px 0 10px;
  display: block;
  border: 0;
  background: transparent;
  display: none;
}
.hero form button img {
  display: block;
  width: 25px;
}
.hero form input::-moz-placeholder {
  -moz-user-select: none;
       user-select: none;
}
.hero form input:-ms-input-placeholder {
  -ms-user-select: none;
      user-select: none;
}
.hero form input::placeholder {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.fullbox {
  height: 100vh;
  display: grid;
  place-content: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.fullbox .err {
  display: flex;
  gap: 30px;
}
.fullbox .err span {
  font-size: 22px;
  font-family: Satoshi-bold;
}
.fullbox .err hr {
  height: 100%;
  width: 3px;
  background: #000;
}
.fullbox button {
  width: 150px;
  height: 45px;
  border: 0;
  background-color: lightgrey;
  font-family: satoshi-medium;
  cursor: pointer;
  border-radius: 5px;
  display: block;
  margin: 0 auto;
  margin-top: 30px;
  transition: all 0.2s;
}
.fullbox button:hover {
  background-color: #000;
  color: #fff;
}

.main .flx {
  display: flex;
  gap: 100px;
  align-items: center;
  padding: 50px 0;
  margin-top: 30px;
}
.main .flx .userimg {
  width: 200px;
  height: 200px;
  min-width: 200px;
  outline: 4px solid #0366d6;
  border-radius: 50%;
  overflow: hidden;
  outline-offset: 3px;
}
.main .flx .userimg img {
  width: 100%;
}
.main .flx .userdata h2 {
  font-size: 28px;
  font-family: Satoshi-Medium;
}
.main .flx .userdata h2 span {
  font-size: 14px;
  margin-left: 5px;
}
.main .flx .userdata p {
  font-size: 18px;
  font-family: Satoshi-Medium;
  margin-top: 10px;
}
.main .flx .userdata p span {
  font-family: Satoshi-Medium;
  font-size: 15px;
}
.main .flx .userdata .fol {
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 50px;
}
.main .flx .userdata .fol p {
  font-size: 16px;
  margin: 0;
  cursor: pointer;
  color: #0366d6;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.main .flx .userdata .joined,
.main .flx .userdata .about {
  color: grey;
}
.main .flx .userdata .otherinfo {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 10px;
}
.main .flx .userdata .otherinfo a,
.main .flx .userdata .otherinfo span {
  display: flex;
  gap: 6px;
  align-items: center;
  color: grey;
  max-width: 250px;
  overflow: hidden;
  position: relative;
}
.main .flx .userdata .otherinfo a img,
.main .flx .userdata .otherinfo span img {
  width: 20px;
}
.main .socialbox {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.4);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  z-index: 1000000;
}
.main .closeoverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.main .bx {
  width: 60%;
  max-width: 600px;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px 40px;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  border-radius: 20px 20px 0 0;
  max-height: 600px;
  overflow-y: scroll;
  z-index: 1000;
  -webkit-animation: slide 0.2s linear;
          animation: slide 0.2s linear;
}
@-webkit-keyframes slide {
  0% {
    transform: translatey(100%) translateX(-50%);
  }
  100% {
    transform: translatey(0) translateX(-50%);
  }
}
@keyframes slide {
  0% {
    transform: translatey(100%) translateX(-50%);
  }
  100% {
    transform: translatey(0) translateX(-50%);
  }
}
.main .bx .indicator {
  text-align: center;
  font-size: 22px;
  font-family: Satoshi-Medium;
}
.main .bx .person {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 25px 0;
}
.main .bx .person .details {
  display: flex;
  align-items: center;
  gap: 20px;
}
.main .bx .person .details img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.main .bx .person .details p {
  font-size: 18px;
  font-family: Satoshi-Medium;
}
.main .bx .person button {
  background-color: #d0d0d0;
  border: 0;
  padding: 8px 20px;
  border-radius: 5px;
  font-family: Satoshi-Medium;
  cursor: pointer;
}
.main .bx .person button:hover {
  background-color: #000;
  color: #fff;
}
.main .bx::-webkit-scrollbar {
  width: 3px;
  padding-top: 10px;
  overflow: hidden;
}
.main .bx::-webkit-scrollbar-button {
  height: 10px;
  opacity: 0;
}
.main .bx::-webkit-scrollbar-thumb {
  background-color: #bfbfbf;
  border-radius: 10px;
}
.main .repos {
  margin-top: 50px;
  position: relative;
}
.main .repos h2 {
  text-align: center;
}
.main .repos .norepos {
  position: absolute;
  width: 100%;
  text-align: center;
}
.main .repos .norepos p {
  text-align: center;
  font-size: 18px;
  font-family: Satoshi-Medium;
}
.main .repos .reposboxes {
  margin-top: 100px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}
.main .repos .reposboxes .repo_single {
  background-color: #fff;
  padding: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 10px;
  position: relative;
}
.main .repos .reposboxes .repo_single img {
  width: 60px;
}
.main .repos .reposboxes .repo_single h3 {
  margin-top: 30px;
  font-size: 18px;
  font-family: Satoshi-Medium;
}
.main .repos .reposboxes .repo_single p {
  font-size: 14px;
  font-family: Satoshi-Medium;
  margin-top: 10px;
  height: 60px;
  position: relative;
  z-index: 10;
  overflow: hidden;
}
.main .repos .reposboxes .repo_single span {
  font-size: 14px;
  font-family: Satoshi-Medium;
}
.main .repos .reposboxes .repo_single p::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 64.06%);
}
.main .repos .reposboxes .repo_single .infos {
  display: flex;
  margin-top: 30px;
  align-items: center;
  gap: 30px;
}
.main .repos .reposboxes .repo_single .infos img {
  width: 20px;
}
.main .repos .reposboxes .repo_single .infos a,
.main .repos .reposboxes .repo_single .infos span {
  display: block;
  font-family: Satoshi-Medium;
  display: flex;
  align-items: center;
  gap: 10px;
}

.loader {
  position: fixed;
  height: calc(100vh - 103px);
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 1000000;
  display: grid;
  place-content: center;
}
.loader .spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #d0d0d0;
  border-radius: 50%;
  border-left: 4px solid #000;
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 767px) {
  .main .flx {
    flex-direction: column;
    gap: 50px;
  }
  .main .flx .userdata {
    width: 100%;
    text-align: center;
  }
  .main .flx .userdata .fol,
.main .flx .userdata .otherinfo {
    justify-content: center;
  }
  .main .repos .reposboxes {
    grid-template-columns: repeat(2, 1fr);
  }
  .main .bx {
    width: 80%;
  }
}
@media (max-width: 650px) {
  .main .repos .reposboxes {
    gap: 15px;
  }
}
@media (max-width: 550px) {
  .main .flx .userdata .otherinfo {
    flex-direction: column;
    margin-top: 20px;
  }
  .main .bx .person .details p {
    font-size: 16px;
  }
  .main .bx {
    width: 90%;
    padding: 20px;
  }
  .main .bx .person .details {
    gap: 10px;
  }
  .main .bx .person .details img {
    width: 40px;
    height: 40px;
  }
  .main .bx .person button {
    padding: 8px 10px;
  }
  .main .repos .reposboxes {
    grid-template-columns: repeat(1, 1fr);
  }
  header nav .container .logo {
    width: 130px;
  }
}
@media (max-width: 767px) {
  .main .flx .userdata h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .main .flx .userdata p,
.main .flx .userdata p span {
    font-size: 14px;
  }
  .main .flx .userdata .otherinfo {
    gap: 15px;
  }
  .hero {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .hero form {
    padding: 0 10px;
    width: 90%;
    gap: 0;
  }
  .hero form input {
    height: 55px;
  }
  .hero form button {
    display: block;
  }
  .hero form img {
    display: none;
  }
  input::-moz-placeholder {
    font-size: 14px;
  }
  input:-ms-input-placeholder {
    font-size: 14px;
  }
  input::placeholder {
    font-size: 14px;
  }
  .hero form input {
    width: 100%;
    font-size: 16px;
  }
  .pcmenu {
    display: none;
  }
  header nav .container .mobilemenu {
    display: block;
    position: relative;
  }
  header nav .container ul {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 50px;
    position: absolute;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    background: #fff;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    right: 0;
    top: -10pz;
    z-index: 10000000;
    padding: 0 100px;
    padding-top: 70px;
    padding-bottom: 30px;
    background: rgba(255, 255, 255, 0.8);
    -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 0 0 10px 10px;
  }
  header nav .container ul .close {
    display: block;
  }
}/*# sourceMappingURL=App.css.map */